<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h3>Informes facturas</h3>
            </div>            
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <section class="content">
        <div style="display: grid; gap: 30px; grid-template-columns: 1fr 1fr 1fr 1fr;" >
            <div class="form-group">
                <label for="">Empresa</label>
                <select v-model="empresaseleccionada" id="empresa" class="form-control">
                    <option value="">Todas</option>
                    <option v-for="empresa in empresas" :key="empresa.id_empresa" :value="empresa">{{ empresa.nombre_empresa }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Banco</label>
                <select v-model="bancoseleccionado" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option v-for="banco in bancos" :key="banco.id_banco" :value="banco.id_banco">{{ banco.nombre_banco }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Compañía</label>
                <Dropdown scrollHeight="400px" style="width: 100%;" showClear v-model="companiaseleccionada" class="multiselect-small"
                    :options="companias" filter optionLabel="nombre" placeholder="Todas">
                </Dropdown>
            </div>
            <div class="form-group">
                <label for="">Prefijo/Serie</label>
                <MultiSelect v-model="lineaseleccionada" style="width: 100%;" :options="lineas" optionLabel="nombre_serie" 
                placeholder="Seleccione serie" :maxSelectedLabels="2" class="multiselect-small"/>
            </div>
        </div>
        <div style="display: flex; gap: 20px;">
            <div class="form-group">
                <label for="">Autorizadas</label>
                <select v-model="autorizadasselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Cobrado</label>
                <select v-model="cobradoselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Enviado</label>
                <select v-model="enviadoselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Tramitador</label>
                <select v-model="tramitadorseleciconado" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option v-for="tramitador in tramitadores" :key="tramitador.idusuario" :value="tramitador.idusuario">{{ tramitador.nombre }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Auto-Facturas</label>
                <select v-model="autofacturasselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Factura-Albaranes</label>
                <select v-model="facturaalbaranesselec" id="empresa" class="form-control">
                    <option value="">Todas</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Exportadas</label>
                <select v-model="exportadasselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Categoría</label>
                <select v-model="categoriaselec" id="empresa" class="form-control">
                    <option value="">Todos</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Intervalo de</label>
                <select v-model="intervaloselec" id="empresa" class="form-control">
                    <option value="facturacion">Fechas facturación</option>
                    <option value="cobro">Fechas cobro</option>
                    <option value="autorizacion">Fechas autorización</option>
                    <option value="vencimiento">Fechas vencimiento</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Fecha inicio</label>
                <input v-model="fechainicio" class="form-control" type="date">
            </div>
            <div class="form-group">
                <label for="">Fecha inicio</label>
                <input v-model="fechafin" class="form-control" type="date">
            </div>
        </div>
        <div style="margin-top: 15px;display: flex;justify-content: center;">
            <div class="btn-group">
                <button class="btn btn-sm"><i class="fas fa-list"></i>Listado</button>
                <button class="btn btn-sm"><i class="far fa-file-pdf"></i>PDF</button>
            </div>
        </div>
        <div style="margin-top: 15px;" v-if="mostrarTabla">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            Fecha
                        </th>
                        <th>
                            Fecha Vto.
                        </th>
                        <th>
                            Autorizada
                        </th>
                        <th>
                            Nº
                        </th>
                        <th>
                            Referencia
                        </th>
                        <th>
                            Cliente
                        </th>
                        <th>
                            NIF
                        </th>
                        <th>
                            Dto. Antes IVA (%)
                        </th>
                        <th>
                            Dto. Después IVA (%)
                        </th>
                        <th>
                            Importe
                        </th>
                        <th style="display: flex;">
                            <span> Cobrada </span>
                            <input type="checkbox">
                        </th>
                        <th style="display: flex;">
                            <span>Enviada</span>
                            <input type="checkbox">
                        </th>
                        <th>
                            Exportada
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="factura in facturas" :key="factura.id_factura">
                        <td>
                            {{ factura.fecha_hoy_factura }}
                        </td>
                        <td>
                            {{ factura.vencimiento_factura }}
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            
                        </td>
                        <td>
                            {{ factura.nombre_cliente_factura }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import { PwgsApi } from '../../services/PwgsApi';

export default ({ 
    data() {
        return {
            mostrarTabla:false,
            facturas:[],
            bancos: [],
            empresas: [],
            companias: [],
            lineas: [],
            tramitadores: [],  
            empresaseleccionada: '',
            bancoseleccionado:'',
            companiaseleccionada:'',
            lineaseleccionada:'',
            tramitadorseleciconado:'',
            autofacturasselec:'',
            facturaalbaranesselec:'',
            enviadoselec:'',
            exportadasselec:'',
            cobradoselec:'',
            autorizadasselec:'',
            intervaloselec:'facturacion',
            fechainicio:'',
            fechafin:'',
            //categoriasselec:'',       
        }
    },
    components: {
        Dropdown,
        MultiSelect,
    },
    methods:{
        async cargarBancos(){
            const api = new PwgsApi();
            const resp = await api.get('bancos');
            this.bancos = resp.datos;
        },
        async cargarEmpresas(){
            const api = new PwgsApi();
            const resp = await api.get('empresas');
            this.empresas = resp.datos;
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('companias');
            this.companias = resp.datos;
        },
        async cargarLineas(){
            const api = new PwgsApi();
            const resp = await api.get('lineas-facturacion');
            this.lineas = resp.datos;
        },
        async cargarTramitadores(){
            const api = new PwgsApi();
            const resp = await api.get('usuarios/gestion');
            this.tramitadores = resp.datos;
        },
        async cargarFechas(){
            const fecha = Date.now();
            const hoy = new Date(fecha);
            var año_actual = new Date().getFullYear();
            this.fechainicio= this.fechaing('01/01/'+año_actual);
            this.fechafin = this.fechaing(hoy.toLocaleDateString());
            //this.fechainicio = new Date();
            //this.fechafin = new Date();
        },
        fechaing(fecha) {
            const [dia, mes, año] = fecha.split("/");
            return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },
        fechaesp(fecha) {
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
        },
    },
   
    watch:{
    },
    mounted() {
        this.cargarBancos();
        this.cargarEmpresas();
        this.cargarCompanias();
        this.cargarLineas();
        this.cargarTramitadores();
        this.cargarFechas();
    },    
})


</script>
<style>
</style>